import {Vue as _Vue} from "vue-class-component";
import fetchIntercept from 'fetch-intercept';
import dayjs from "dayjs";
import {
    checkIfStringIsValidISODate,
    convertParamsToObject,
    formatDataTableBeforeSave,
    getDeploymentStage,
    getRelationsToSave,
    getRemovedIdsFromDataTable,
    getValidationErrorsFromDataTable,
    hoursBetween, isDev,
    isDevelopment,
    isLiftbaseUrl,
    LSK_ACCESS_JWT, LSK_ACCESS_JWT_OTHER,
    LSK_REFRESH_JWT, LSK_REFRESH_JWT_OTHER, sortDictionaryOnKeys, omitWarning, isLocalhost
} from "@/utils";
import {decode} from "jsonwebtoken";
import {gqlQuery, gqlQueryRaw} from "@/apollo-graphql/utils";
import gql from "graphql-tag";
import UserService from "@/services/user-service";
import _, {isArray, isEmpty, isObject, toNumber} from "lodash";
import auditLogService, {AuditLog, AuditLogType} from "@/services/audit-log-service";

const jexcelStyle = require('jexcel/dist/jexcel.css');
const jexcel = require('jexcel');


function logout() {
    if (isInternPage()) {
        localStorage.setItem('redirectTo', window.location.hash)
        setIfFromPunchIn()
        saveLoginInfoFromUrl()
    }
    localStorage.removeItem(LSK_REFRESH_JWT_OTHER)
    localStorage.removeItem(LSK_ACCESS_JWT_OTHER)
    localStorage.removeItem(LSK_REFRESH_JWT)
    localStorage.removeItem(LSK_ACCESS_JWT)
    localStorage.removeItem('onboarding-tour')
    // @ts-ignore
    window.location = "#/login";
}

export function setIfFromPunchIn() {
    if (isFromPunchIn()) localStorage.setItem('fromPunchIn', 'true')
}

export function isFromPunchIn() {
    return window.location.href.indexOf("requiresAuthByCredentials") >= 0
}

function saveLoginInfoFromUrl() {
    const url = window.location.hash;
    const loginInfo = convertParamsToObject(url.substring(url.indexOf('?')))
    if (loginInfo?.email) localStorage.setItem('emailToLogin', loginInfo.email)
    if (loginInfo?.tenant) localStorage.setItem('tenantToLogin', loginInfo.tenant)
}

function refreshToken(endpoint: string, from: string, to: string) {
    const token = localStorage.getItem(from)
    if (token) {
        if (isJwtExpired(token)) {
            logout();
        }

        const config = {
            method: "POST",
            headers: {
                "Authorization": "Bearer " + token
            }
        }
        return fetch(endpoint, config)
            .then((response: any) => {
                if (response.ok) {
                    return response.json()
                }
            })
            .then((data: any) => {
                if (data == null) {
                    if (isRefreshRequest(endpoint)) logout()
                    return
                }
                localStorage.setItem(to, data.jwt)
            })
    } else if (isRefreshRequest(endpoint)) {
        logout()
        return new Promise((resolve: any) => resolve())
    } else {
        return new Promise((resolve: any) => resolve())
    }
}

function isRefreshRequest(endpoint: string) {
    return endpoint.indexOf('refresh-token') >= 0
}

/**
 * Refresh and exchange the refresh-token for a short-lived access-token for data access
 */
export function refreshAccessToken() {
    return refreshToken("/api/auth/access-token", LSK_REFRESH_JWT, LSK_ACCESS_JWT)
}

export function refreshAccessTokenOther() {
    return refreshToken("/api/auth/access-token", LSK_REFRESH_JWT_OTHER, LSK_ACCESS_JWT_OTHER)
}

/**
 * Refresh and exchange the refresh-token for a new refresh-token so the user "is not logged out"
 */
export function refreshRefreshToken(typeToken: string) {
    return refreshToken("/api/auth/refresh-token", typeToken, typeToken)
}

export function lbXhrAuthentication() {
    const origOpen = XMLHttpRequest.prototype.open;
    XMLHttpRequest.prototype.open = function (method: string, url: string) {
        const token = localStorage.getItem(LSK_ACCESS_JWT_OTHER) || localStorage.getItem(LSK_ACCESS_JWT)

        //@ts-ignore
        origOpen.apply(this, arguments);

        if (isLiftbaseUrl(url)) {
            this.setRequestHeader("Authorization", "Bearer " + token)
        }
    };

    fetchIntercept.register({
        request(url: string, config: any): any {
            if (isLiftbaseUrl(url)) {
                config = config || {}
                config.headers = config.headers || {}

                if (!config.headers["Authorization"] && url.indexOf('login') < 0) {
                    const tokenOther = localStorage.getItem(LSK_ACCESS_JWT_OTHER)
                    if (!tokenOther || isJwtExpired(tokenOther)) refreshAccessTokenOther()


                    const token = localStorage.getItem(LSK_ACCESS_JWT) as string
                    if (token) {
                        if (isJwtExpired(token)) {
                            // start the refresh process - this request will fail, but thats ok
                            refreshAccessToken()
                        }

                        config.headers["Authorization"] = "Bearer " + (tokenOther || token)
                        setHasuraDefaultRole(config, !tokenOther)
                    } else {
                        logout()
                    }
                }

            }

            return [url, config]
        }
    })

    // if the dont have any token, redirect to login
    const anyToken = !!(localStorage.getItem(LSK_ACCESS_JWT) || localStorage.getItem(LSK_REFRESH_JWT))
    if (!isLoginPage() && !anyToken) {
        logout();
        return new Promise((resolve: any) => resolve())
    }

    checkAndRefreshToken(LSK_REFRESH_JWT)
    checkAndRefreshToken(LSK_REFRESH_JWT_OTHER)

    /**
     * refresh the login token every 10 minutes
     */
    setInterval(refreshAccessToken, 1000 * 60 * 10)
    setInterval(checkAndRefreshAccessTokenOther, 1000 * 60 * 10)
    return refreshAccessToken()
}

function checkAndRefreshAccessTokenOther() {
    const token = localStorage.getItem(LSK_REFRESH_JWT_OTHER) || localStorage.getItem(LSK_ACCESS_JWT_OTHER)
    if (token) checkAndRefreshAccessTokenOther()
}

function checkAndRefreshToken(typeToken: string) {
    const refreshJwt = localStorage.getItem(typeToken) as string;
    if (refreshJwt) {
        const refreshTokenExpiration = getJwtExpiration(refreshJwt)
        const now = new Date()
        const number = hoursBetween(refreshTokenExpiration, now);
        if (number < 32) refreshRefreshToken(typeToken)
    }
}

function setHasuraDefaultRole(config: any, loggedUser: boolean = true) {
    const user = loggedUser ? UserService.getLoggedUser() : UserService.getCurrentUser()
    const isOperator = user?.jwtUser?.roles?.find((role: any) => role == 'user-operator')
    if (isOperator && isAccessingFromOperatorArea()) {
        config.headers["x-hasura-role"] = "user-operator"
    }
}

export function isAccessingFromOperatorArea() {
    return window.location.hash.indexOf('operator') >= 0;
}

/**
 * Checks if the given jwt
 */
export function isJwtExpired(token: string) {
    const validTo = getJwtExpiration(token)
    const now = new Date()
    return now > validTo
}

export function getJwtExpiration(token: string) {
    const decoded = decode(token)
    const exp = decoded["exp"]
    return new Date(exp * 1000)
}

function isLoginPage() {
    return window.location.hash.indexOf('login') >= 0;
}

function isInternPage() {
    return window.location.hash.indexOf('login') < 0
        && window.location.hash.indexOf('logout') < 0
        && window.location.hash.indexOf('graphql') < 0
        && getDeploymentStage() != null
}

export class Vue extends _Vue {

    /**
     * "element-plus"-based notify service
     */
    $message: any | {
        title: string,
        message: string,
        type: string
    }
    $prompt: any
    $confirm: any
    $alert: any

    // i18n
    $t: any
    $i18n: any

    languages = [
        {label: "Deutsch", value: "de"},
        {label: "English", value: "en"},
        {label: "Italiano (Beta)", value: "it"},
        {label: "Čeština (Beta)", value: "cz"},
        {label: "Slovenčina (Beta)", value: "sk"},
    ]

    txtBeforeRouteLeave = "Ihre Änderungen werden eventuell nicht gespeichert. Sind Sie sicher, dass Sie fortfahren wollen?";

    jExcelObj: any = null
    jExcelOptions: any
    jExcelColumns: any
    jExcelValidationErrors: any = []

    viewMode = 'list'
    search: string = ""
    searchRegEx: RegExp = /.*/

    /**
     * used to prevent user leaving the page if it has unsaved changes
     */
    static hasUnsavedChanges: boolean = false;


    /**
     * "mitt"-based event bus
     */
    emitter: any

    /**
     * Global currency config
     */
    currencyConfig = {
        decimal: ",",
        thousands: ".",
        precision: 2,
        suffix: ' €',
        disableNegative: true
    }

    currencies = [
        {
            id: 'EUR',
            label: '€',
            value: 'EUR',
            name: 'EUR',
            suffix: true
        }, {
            id: 'USD',
            label: 'US$',
            value: 'USD',
            name: 'USD',
            prefix: true
        }]

    getCurrencyConfig(currency: string) {
        const currencyConfig = this.getCurrency(currency)
        const suffix = currencyConfig?.suffix ? ' ' + currencyConfig?.label : ''
        const prefix = currencyConfig?.prefix ? currencyConfig?.label + ' ' : ''
        return {
            ...this.currencyConfig,
            prefix,
            suffix,
        }
    }

    getCurrency(currency: string) {
        return this.currencies.find((item: any) => item.value == currency)
    }


    /**
     * START: GLOBAL config
     * */
    findConfigByKey(key: string) {
        return gqlQueryRaw(this.globalConfigsGql, {key}).then((result: any) => {
            return result.data.config[0]
        })
    }

    globalConfigsGql = gql`
        query getConfig(
            $key: String!
        ) {
            config:global_configuration(where: {key: {_eq: $key}}) {
                id
                key
                type
                value
                group
                label
            }
        }
    `
    configurationIsLoading: any = false
    thereIsNoConfiguration: any = false
    tenantLanguageIsLoading: any = false

    get configurations() {
        if (!this.isLogged()) {
            sessionStorage.removeItem("configuration")
            return []
        }

        let configs: any = JSON.parse(sessionStorage.getItem("configuration") || "{}")
        if (!_.isEmpty(configs) || this.thereIsNoConfiguration) return configs
        else if (!this.configurationIsLoading) {
            gqlQueryRaw(this.configGql).then((result: any) => {
                configs = result.data?.list
                if (configs?.length === 0) this.thereIsNoConfiguration = true
                sessionStorage.setItem("configuration", JSON.stringify(configs))
                this.configurationIsLoading = false
                this.$forceUpdate()
            })
            this.configurationIsLoading = true
        }
        return []
    }

    get tenantLanguage() {
        if (!this.isLogged()) {
            sessionStorage.removeItem("main_language")
            return []
        }

        let mainLanguage: any = sessionStorage.getItem("main_language")
        if (!!mainLanguage && mainLanguage != '') return mainLanguage
        else if (!this.tenantLanguageIsLoading) {
            gqlQueryRaw(this.tenantLanguageGql).then((result: any) => {
                sessionStorage.setItem("main_language", result.data?.tenant[0]?.main_language || 'de')
                this.tenantLanguageIsLoading = false
                this.$forceUpdate()
            })
            this.tenantLanguageIsLoading = true
        }
        return 'de'
    }

    refreshConfigurations() {
        if (!isAccessingFromOperatorArea()) {
            sessionStorage.removeItem("configuration")
            this.thereIsNoConfiguration = false
        }
        return this.configurations
    }

    refreshMainLanguage() {
        if (!isAccessingFromOperatorArea()) sessionStorage.removeItem("main_language")
        return this.tenantLanguage
    }

    configGql = gql`
        query GetConfiguration{
            list: configuration(order_by: {id:asc}, where:{model: {_neq: "password"}}){
                id
                key
                value
                model
                lb_user{
                    id
                    name
                    is_group
                    user_addresses (where: {active: {_eq: true}}){
                        id
                        lb_user_id
                        name
                        address
                        zipcode
                        consignee
                        city
                        complement
                        dropoff_location
                        country
                        active
                    }
                }
                supplier{
                    id
                    name
                    email
                    supplier_approvers {
                        supplier_id
                        required
                        lb_user_id
                        lb_user {
                            name
                            approval_limit
                            active
                            is_group
                            task_group_users {
                                lb_user {
                                    id
                                    name
                                    approval_limit
                                }
                            }
                        }
                    }
                }
            }
        }
    `


    tenantLanguageGql = gql`
        query GetTenantLanguageGql{
            tenant {
                main_language
            }
        }
    `

    /**
     * END: GLOBAL config
     * */

    mounted() {
        this.setCommonTexts()
    }

    setCommonTexts() {
        this.txtBeforeRouteLeave = this.$t('txt_before_leave_page')
    }

    /**
     * START: Tour - Onboarding
     * */
    $tours: any

    startTourIfFirstTime(tourName: string, callbackFn: any = null) {
        if (!isAccessingFromOperatorArea())
            this.isTourGlobalActived().then((active: any) => {
                if (active)
                    gqlQueryRaw(this.toursGql, {page: tourName}).then((result: any) => {
                        if (result.data.tours?.length == 0) {
                            this.stopTours(tourName)
                            this.$tours[tourName].start()
                            if (callbackFn) callbackFn()
                        }
                    })
            })
    }

    isTourGlobalActived() {
        const active = localStorage.getItem('onboarding-tour')
        if (active == 'true') return new Promise((resolve: any) => resolve(true));
        else if (active == 'false') return new Promise((resolve: any) => resolve(false));
        return this.findConfigByKey('onboarding-tour').then((config: any) => {
            localStorage.setItem('onboarding-tour', config.value)
            if (config.value == 'true') return new Promise((resolve: any) => resolve(true));
            return new Promise((resolve: any) => resolve(false));
        })
    }

    stopTours(exceptName?: string) {
        _.keys(this.$tours)?.forEach((name, index) => {
            if ((!exceptName || name !== exceptName) && this.isTourRunning(name)) {
                this.forceStopTour(name)
            }
        })
    }

    isTourRunning(name: string) {
        return this.$tours[name]?.isRunning?.value == true
    }

    forceStopTour(name: string) {
        this.$tours[name]?.finish()
        this.emitter.emit("stop-tour", {name: name})
    }

    getTourTarget(steps: any[]) {
        return steps.find((step: any) => document.querySelector(step.target))
    }

    toursGql = gql`
        query getTour(
            $page: String!
        ) {
            tours:tour(where: {page: {_eq: $page}}) {
                id
            }
        }
    `

    scrollTop() {
        this.$el.parentElement.scrollTop = 0
    }

    /**
     * END: Tour - Onboarding
     * */


    private getUserGql = gql`
        query findUser($id: bigint!) {
            user:lb_user_by_pk(id:  $id) {
                id
                email
                name
                approval_limit
                tenant {
                    name
                }
                department_id
                department {
                    id
                    active
                    name: translated_name
                    responsible {
                        id
                        name
                    }
                    user_clearing_id
                    user_clearing {
                        id
                        name
                    }
                    user_select_product_id
                    user_select_product {
                        id
                        name
                    }
                    user_send_order_id
                    user_send_order {
                        id
                        name
                    }
                    user_consignee_id
                    user_consignee {
                        id
                        name
                        user_addresses (where: {active: {_eq: true}}){
                            id
                            lb_user_id
                            name
                            address
                            zipcode
                            consignee
                            city
                            complement
                            dropoff_location
                            country
                            active
                        }
                    }
                    department_approvers {
                        department_id
                        required
                        lb_user_id
                        lb_user {
                            name
                            id
                            approval_limit
                            active
                            is_group
                            task_group_users {
                                lb_user {
                                    id
                                    name
                                    approval_limit
                                }
                            }
                        }
                    }
                }
                cost_center_id
                cost_center {
                    id
                    name: translated_name
                    number
                    active
                    budgets {
                        id
                        name
                        not_allocated
                        ledger_account_id
                        start
                        end
                        active
                        value
                        ledger_account {
                            name
                        }
                    }
                    cost_center_approvers {
                        cost_center_id
                        lb_user_id
                        required
                        lb_user {
                            name
                            id
                            approval_limit
                            active
                            is_group
                            task_group_users {
                                lb_user {
                                    id
                                    name
                                    approval_limit
                                }
                            }
                        }
                    }
                }
                roles{
                    role
                }
                client_id
                client {
                    cost_center_required
                    responsible {
                        id
                        name
                    }
                    user_clearing_id
                    user_clearing {
                        id
                        name
                    }
                    user_select_product_id
                    user_select_product {
                        id
                        name
                    }
                    user_send_order_id
                    user_send_order {
                        id
                        name
                    }
                    user_consignee_id
                    user_consignee {
                        id
                        name
                        user_addresses (where: {active: {_eq: true}}){
                            id
                            lb_user_id
                            name
                            address
                            zipcode
                            consignee
                            city
                            complement
                            dropoff_location
                            country
                            active
                        }
                    }
                    id
                    active
                    name
                    min_approval
                    address
                    zipcode
                    city
                    address_complement
                    address_name
                    consignee
                    dropoff_location
                    country
                    phone_country_code
                    phone_number
                    client_addresses(where: {active: {_eq: true}}){
                        id
                        address
                        zipcode
                        city
                        active
                        default
                        client_id
                        name
                        complement
                        consignee
                        dropoff_location
                        country
                        phone_country_code
                        phone_number
                    }
                }
                user_addresses(where: {active: {_eq: true}}){
                    id
                    lb_user_id
                    name
                    address
                    complement
                    active
                    consignee
                    country
                    phone_number
                    phone_country_code
                    dropoff_location
                    city
                    zipcode
                }
            }
        }
    `
    private getSimpleUserInfosGql = gql`
        query findUser($id: bigint!) {
            user:lb_user_by_pk(id:  $id) {
                id
                email
                name
                tenant {
                    name
                }
                roles{
                    role
                }
            }
        }
    `

    lbTitle(title: string) {
        document.title = "liftbase - " + title
    }

    public lbMutateUX(promise: any) {
        const that = this
        return promise.then((value: any) => {
            //@ts-ignore
            that.$message({
                title: "Gespeichert",
                message: that.$t('saved_success'),
                type: "success",
                duration: 1250
            })
            return value;
        }).catch((error: any) => {
            //@ts-ignore
            that.$message({
                title: "Fehler",
                message: that.$t('save_error'),
                type: "error"
            })
            console.error(error)
            throw error;
        })
    }

    lbMutate(this: any, mutation: any, data: any) {
        return this.lbMutateUX(mutation.mutate(data))
    }

    lbFetch(
        this: Vue,
        {
            url = "",
            method = "POST",
            headers = {
                "Content-Type": "application/json"
            },
            data = null as any,
            successTitle = "",
            successMessage = "",
            errorTitle = "",
            errorMessage = "",
            showError = true,
            success = function (this: Vue, data: any): boolean | void {
                return false
            },
            error = function (this: Vue, error: any): boolean | void {
                return false
            }
        }
    ) {
        const that = this
        return fetch(url, {
            method: method,
            headers: headers,
            body: data && JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.text()
            }

            if (!error.apply(that, [response]) && showError) {
                //@ts-ignore
                that.$message({
                    title: errorTitle || that.$t('error_title'),
                    message: errorMessage || that.$t('request_error'),
                    type: "error"
                })
            }
        }).then(result => {
            if (result == null) return

            let data
            try {
                data = JSON.parse(result)
            } catch (e) {
                data = {}
            }
            if (!success.apply(that, [data]) && successTitle) {
                //@ts-ignore
                that.$message({
                    title: successTitle,
                    message: successMessage,
                    type: "success"
                })
            }
        }).catch(exception => {
            if (!error.apply(that, [exception]) && showError) {
                //@ts-ignore
                that.$message({
                    title: errorTitle || that.$t('error_title'),
                    message: errorMessage || that.$t('request_error'),
                    type: "error"
                })
            }
        })
    }

    lbNowIso() {
        return dayjs().format("YYYY-MM-DD")
    }

    lbDateIso(date: string) {
        return dayjs(date).format("YYYY-MM-DD")
    }

    lbDate(date: string) {
        return dayjs(date).format("DD.MM.YYYY")
    }

    lbDateTime(date: string) {
        return dayjs(date).format("DD.MM.YYYY HH:mm")
    }

    lbNewWindow(
        this: Vue,
        {
            url = "",
            features = {
                scrollbars: "yes",
                status: "yes"
            },
            onClosed = (window: Window) => {
            },
            onOpen = (window: Window) => {
            },
            onError = () => {
            }
        }
    ) {
        const featuresString = Object.entries(features)
            .map((entry: any) => entry.key + "=" + entry.value)
            .join(",")

        const newWindow = window.open(url, '_blank', featuresString)
        if (!newWindow || newWindow.closed || typeof newWindow.closed == 'undefined') {
            onError()
            return
        }
        onOpen(newWindow)

        var timer = setInterval(function () {
            if (newWindow.closed) {
                clearInterval(timer);
                onClosed(newWindow)
            }
        }, 100);
    }

    isDev() {
        return isDev()
    }

    isLocalhost() {
        return isLocalhost()
    }

    /**
     * Dynamics Account Combinations
     * */



    findCombinationGql = gql`
        query FindCombination($combination: String = "", $account_structure: [String!]! = []) {
            combinations:account_combination(
                where: {
                    display_value: {_iregex: $combination},
                    ms365_account_structure_name: {_in: $account_structure},
                    active: {_eq: true}
                },
                order_by: {display_value: asc}
            ) {
                display_value
                cost_center_id
                cost_center {
                    number
                    name: translated_name
                }
                profit_center_id
                profit_center {
                    number
                    name
                }
                ledger_account_id
                ledger_account {
                    number
                    name: translated_name
                }
                fk_4
                fk_5
                fk_6
            }
        }
    `

    mappingFinancialDimension = gqlQuery(gql`
        query FindMapping{
            list: liftbase_d365 {
                id
                d365_financial_dimension
                invoice_foreign_key
            }
        }
    `)

    d365IntegrationFormat = gqlQuery(gql`
        query FindCombinationFormat {
            d365_account_combination_format(
                where: {
                    active: {_eq: true}
                },
                order_by: {format: desc},
                limit: 1
            ) {
                format
            }
        }
    `)

    allFinFieldsToValidate = [
        "cost_center_id", "ledger_account_id", "profit_center_id", "d365_department_id",
        "employee_id", "branch_id", "d365_vehicle_id", "d365_sales_channel_id", "d365_area_id",
        "d365_labor_wert_id"
    ]

    get d365IntegrationDimensions(): string[] {
        return this.d365IntegrationFormat?.result?.d365_account_combination_format[0]?.format?.split("-") ?? []
    }

    get mappingFinancialDimensions() {
        return this.mappingFinancialDimension?.result?.list
    }

    get objectsToOmitFromFinancialDimensions() {
        return this.mappingFinancialDimensions?.map((dimension: any) => dimension.invoice_foreign_key?.toString().replace("_id", ""))
    }

    async checkCombination(combination: any, client: any) {
        const account_structure = [""].concat(client?.ms365_account_structure_name?.split(';') || "")
        const result = await gqlQueryRaw(this.findCombinationGql, {combination, account_structure})
        const combinations = (result.data?.combinations.length > 0) ?
            result.data.combinations.filter((item: any) => item.display_value.startsWith(combination)) : []

        return {valid: combinations.length > 0, combinations}
    }

    /**
     * END: Dynamics Account Combinations
     */

    confirmBeforeDelete(row: any, callback: any, msg?: string, extraParam?: any) {
        const text = msg ?? this.$t('admin_page.confirm_before_delete_msg', {id: row.id})
        this.$confirm(text, this.$t('delete'), {
            confirmButtonText: this.$t('delete'),
            confirmButtonClass: 'el-button--danger solid',
            cancelButtonText: this.$t('cancel'),
            center: true
        })
            .then(() => {
                if (extraParam) callback(row, extraParam)
                else callback(row)
            })
            .catch(() => {

            })
    }

    confirmBeforeDeleteWithInput(row: any, callback: any, msg?: string, msgError?: string) {
        const text = msg ?? this.$t('admin_page.confirm_before_delete_from_db_msg', {id: row.id})
        this.$prompt(text, this.$t('admin_page.confirm_before_delete_from_db_title'), {
            confirmButtonText: this.$t('admin_page.confirm_before_delete_from_db_btn'),
            confirmButtonClass: 'el-button--danger solid',
            cancelButtonText: this.$t('cancel'),
            center: true,
            dangerouslyUseHTMLString: !!msg
        })
            .then((input: any) => {
                if (row.name == input.value)
                    callback(row)
                else
                    this.$alert(msgError)
            })
            .catch(() => {

            })
    }

    confirmBeforeToggleBlock(row: any, callback: any) {
        let txt = row.blocked ?
            this.$t('admin_page.unblock_user_msg', {name: row.name}) :
            this.$t('admin_page.block_user_msg', {name: row.name})
        const txtBtn = row.blocked ? this.$t('admin_page.unblock_user_btn') : this.$t('admin_page.block_user_btn')
        const txtTitle = row.blocked ? this.$t('admin_page.unblock_user_title') : this.$t('admin_page.block_user_title')
        this.$confirm(txt, txtTitle, {
            confirmButtonText: txtBtn,
            confirmButtonClass: 'el-button--warning',
            cancelButtonText: this.$t('cancel'),
            center: true
        })
            .then(() => {
                callback(row)
            })
            .catch(() => {

            })
    }

    getCurrentUser() {
        return UserService.getCurrentUser()
    }

    getLoggedUser() {
        return UserService.getLoggedUser()
    }

    isLogged() {
        return !!this.getCurrentUser()?.jwtUser?.userId;
    }

    get userPermissions() {
        return this.getCurrentUser()?.jwtUser?.permissions;
    }

    get userLang() {
        return this.getCurrentUser()?.jwtUser?.lang
    }

    joinArrayToString(array: any) {
        if (!array) return '{}'
        return `{${array.join(',')}}`
    }

    get isOperator() {
        return !!this.getCurrentUser()?.jwtUser?.roles?.find((role: any) => role == 'user-operator')
    }

    get isAdmin() {
        return !!this.getCurrentUser()?.jwtUser?.roles?.find((role: any) => role == 'user-admin')
    }

    get hasAccessToDashboard() {
        return this.isAdmin || (!!this.getCurrentUser()?.jwtUser?.roles?.find((role: any) => role == 'user-dashboard')
            && this.hasAccessToPrices)
    }

    get hasAccessToPrices() {
        return this.isAdmin || (!!this.getCurrentUser()?.jwtUser?.roles?.find((role: any) => role == 'user-price'))
    }

    get hasAccessToAllProcurements() {
        return this.isAdmin || (!!this.getCurrentUser()?.jwtUser?.roles?.find((role: any) => role == 'user-see-all-orders'))
    }

    get hasAccessToAllProcurementsBelongingToOwnCompany() {
        return this.isAdmin || (!!this.getCurrentUser()?.jwtUser?.roles?.find((role: any) => role == 'user-see-all-orders-belonging-to-own-company'))
    }

    get hasAccessToAllProcurementsBelongingToOwnDepartment() {
        return this.isAdmin || (!!this.getCurrentUser()?.jwtUser?.roles?.find((role: any) => role == 'user-see-all-orders-belonging-to-own-department'))
    }


    get hasAccessToOwnProcurementsOrIfInvolved() {
        return this.isAdmin || (!!this.getCurrentUser()?.jwtUser?.roles?.find((role: any) => role == 'user-see-own-orders-and-involved'))
    }

    get hasAccessToAllInvoices() {
        return this.isAdmin || (!!this.getCurrentUser()?.jwtUser?.roles?.find((role: any) => role == 'user-see-all-invoices'))
    }

    get canEditInvoices() {
        return this.isAdmin || (!!this.getCurrentUser()?.jwtUser?.roles?.find((role: any) => role == 'user-can-change-invoices'))
    }

    get canExportInvoice() {
        return this.isAdmin || (!!this.getCurrentUser()?.jwtUser?.roles?.find((role: any) => role == 'user-export-invoice'))
    }

    get exportModuleName() {
        return this.isDatevExportActive() ? this.$t('configuration.datev.description') : this.$t('configuration.ms365.description')
    }

    get canDeleteDocumentDirectly() {
        return this.isAdmin || (!!this.getCurrentUser()?.jwtUser?.roles?.find((role: any) => role == 'user-can-delete-document-directly'))
    }

    get canInsertProduct() {
        return this.isAdmin || (!!this.getCurrentUser()?.jwtUser?.roles?.find((role: any) => role == 'user-can-insert-product'))
    }

    get hasAccessToAllClientAddresses() {
        return this.isAdmin || (!!this.getCurrentUser()?.jwtUser?.roles?.find((role: any) => role == 'user-see-client-addresses'))
    }

    get messageConfirmBeforeLeaveInvoiceForm() {
        return this.$t('invoice.page.confirm_before_leave_msg')
    }

    get messageConfirmBeforeLeaveProcurementForm() {
        return this.$t("procurement.page.before_leave_msg")
    }

    get isChangingProcurementAllowed() {
        return this.isAdmin || (!!this.getCurrentUser()?.jwtUser?.roles?.find((role: any) => role == 'user-change-order'))
    }

    getUserInfo(callback: any, loggedUser: boolean = false) {
        let currentUser = loggedUser ? this.getLoggedUser() : this.getCurrentUser()
        if (currentUser) {
            return gqlQueryRaw(isAccessingFromOperatorArea() ? this.getSimpleUserInfosGql : this.getUserGql,
                {id: currentUser.jwtUser.userId}
            ).then((result: any) => {
                if (loggedUser && !result?.data?.user) logout()
                return callback(result.data.user)
            })
        } else return new Promise((resolve: any) => resolve(false));
    }

    getCurrencyFormat(value: number, locale: string = 'de-DE', showSymbol: boolean = true, currency: string = "EUR") {
        const options = showSymbol ? {style: "currency", currency} : {}
        const formatter = new Intl.NumberFormat(locale ?? 'de-DE', options)
        let formattedValue = formatter.format(value)

        if (currency === 'EUR') {
            formattedValue = formattedValue.replace(/€/g, '') + '€'
        } else if (currency === 'USD') {
            formattedValue = 'US$ ' + formattedValue.replace(/\$/g, '')
        }

        return formattedValue
    }

    getPercentFormat(value: number, locale: string = 'de-DE') {
        let percent = Intl.NumberFormat(locale, {
            style: 'percent',
            minimumFractionDigits: 1,
            maximumFractionDigits: 2
        });
        return percent.format(value / 100)
    }

    notifyUserOfIncomingTask(timelineId?: number) {
        this.lbFetch({
            url: "/api/events/afterAccept?timelineId=" + timelineId,
            method: "POST"
        })
    }

    notifyUserTaskWasTaken(timeline: any) {
        const userId = timeline.current_entry[0].lb_user_id
        const loggedUserId = this.getCurrentUser()?.jwtUser?.userId
        if (userId != loggedUserId) {
            const urlQuery = `userId=${userId}`
            const action = 'change-editor'
            this.lbFetch({
                url: `/api/events/${action}?timelineId=${timeline.id}&${urlQuery}`,
                method: "POST"
            })
        }
    }

    setFocusOnFirstError(that: any, errors: any) {
        const field = _.keys(errors)[0];
        (that.$refs[field] as any)?.$el.parentNode.parentNode.scrollIntoView({block: 'start'});
        if ((that.$refs[field] as any).focus) {
            that.$nextTick(() => (this.$refs[field] as any).focus());
        } else if ((this.$refs[field] as any).$refs[field]?.focus) {
            that.$nextTick(() => (this.$refs[field] as any).$refs[field]?.focus());
        } else if ((this.$refs[field] as any).$refs[field]?.$refs[field]?.focus) {
            that.$nextTick(() => (this.$refs[field] as any).$refs[field]?.$refs[field]?.focus());
        }
    }

    getFirstChars(text: string, count: number = 25) {
        return !!text && text.length > count ? text.substring(0, count - 5) + '...' : text
    }


    onModeViewChange(newValue: string, callbackFn?: any) {
        if (!Vue.hasUnsavedChanges || window.confirm(this.txtBeforeRouteLeave)) {
            Vue.hasUnsavedChanges = false
            this.viewMode = newValue
            if (newValue == 'list' && callbackFn) callbackFn()
            this.$router.push({query: {viewMode: newValue}})
        }
    }

    changeLanguage(language?: string) {
        if (!language) return
        const wasChanged = this.languageWasChanged(language)
        localStorage.setItem('lang', language)
        this.$i18n.setLocale(language)
        if (wasChanged) window.location.reload()
    }

    languageWasChanged(language: string) {
        return localStorage.getItem('lang') != language
    }

    get currentLanguage() {
        return localStorage.getItem('lang') || 'de'
    }

    getTranslationForCurrentLanguageOrFallback(item: any) {
        return this.getTranslation(item) ?? this.$t('translation-component.no_translation')
    }

    getTranslation(item: any, listName: string = 'translations', fieldName: string = 'translation', defaultLanguage?: string) {
        if (!item) return ''

        const list = isArray(item[listName]) ? item[listName]?.filter((it: any) => it[fieldName] != "") :
            (isArray(item[listName]?.data) ? item[listName].data?.filter((it: any) => it[fieldName] != "") :
                [])

        if (defaultLanguage) {
            const translation = list?.find((item: any) => item.language === defaultLanguage)
            if (translation && translation[fieldName]) return translation[fieldName]
        }

        const hasMoreTranslations = list?.length > 0
        const translation = hasMoreTranslations ?
            list.find((item: any) => item.language === this.currentLanguage) ||
            list.find((item: any) => item.language === this.tenantLanguage) ||
            list[0] : null

        return translation ? translation[fieldName] : item[fieldName] ?? item?.name
    }

    getTranslationArray(item: any, listName: string = 'translations', fieldName: string = 'translation') {
        return item[listName]?.map((translation: any) => translation[fieldName]) ?? []
    }

    hasTranslationWithValue(item: any, searchedValue: string, listName: string = 'translations', fieldName: string = 'translation') {
        return this.getTranslationArray(item, listName, fieldName).includes(searchedValue)
    }

    getTranslatedJExcelList(list: any, language?: string, listName: string = 'translations', fieldName: string = 'translation') {
        return list.map((item: any) => {
            return {
                id: item.id,
                name: this.getTranslation(item, listName, fieldName, language)
            }
        })
    }

    sortTranslation(rowA: any, rowB: any) {
        return this.sort(
            this.getTranslationForCurrentLanguageOrFallback(rowA)?.toLowerCase(),
            this.getTranslationForCurrentLanguageOrFallback(rowB)?.toLowerCase()
        )
    }


    sortedProp: any = null
    sortedColumnId: any = null
    order: any = null

    sortChange(column: any) {
        if (this.sortedColumnId) {
            const elColOld = (document.querySelector(`th.${this.sortedColumnId}`)) as HTMLElement
            if (elColOld) elColOld.classList.remove(this.order)
        }

        this.sortedColumnId = column.column.id
        this.sortedProp = column.prop
        this.order = column.order

        setTimeout((this.$refs.list as any)?.clearSort(), 1000)

        const elColNew = (document.querySelector(`th.${this.sortedColumnId}`)) as HTMLElement
        elColNew.classList.add(this.order)
    }


    sort(a: any, b: any) {
        const tempA = typeof a === 'string' ? a.toLowerCase() : a
        const tempB = typeof b === 'string' ? b.toLowerCase() : b
        if (tempA < tempB) return -1
        if (tempA > tempB) return 1
        return 0
    }

    sortProp(a: any, b: any, prop: string) {
        return this.sort(a[prop], b[prop])
    }


    /**
     * START: Functions for JExcel
     * */

    generateSpreadsheet(spreadsheetInstance: any, data: any, columns: any, nestedHeaders?: any, freezeColumns?: any, tableWidth?: string) {
        this.jExcelColumns = columns
        this.jExcelOptions = {
            data: data?.length > 0 ? data : [[0]],
            columns,
            nestedHeaders,
            freezeColumns,
            onchange: this.onCellChanged,
            text: this.$t('jexcel'),
            allowInsertColumn: false,
            allowManualInsertColumn: false,
            allowDeleteColumn: false,
            allowComments: false,
            allowRenameColumn: false,
            defaultColAlign: 'left',
            tableHeight: '600px',
            tableWidth,
            tableOverflow: true,
            csvHeaders: true,
            includeHeadersOnDownload: true
        };
        this.jExcelObj = jexcel(spreadsheetInstance, this.jExcelOptions);
    }

    clearJExcelElement() {
        const table = document.getElementById("spreadsheet")
        if (!!table) table.innerHTML = ""
    }

    onCellChanged(instance: any, cell: any, x: any, y: any, value: any) {
        let cellName = jexcel.getColumnNameFromId([x, y]);
        if (!this.validateCell(x, value)) this.jExcelObj.setStyle(cellName, 'background-color', '#fbc4c4');
        else this.jExcelObj.setStyle(cellName, 'background-color', 'white');
        Vue.hasUnsavedChanges = true;
    }

    invalidateCell(columnIndex: any, rowIndex: any) {
        let cellName = jexcel.getColumnNameFromId([columnIndex, rowIndex]);
        this.jExcelObj.setStyle(cellName, 'background-color', '#fbc4c4');
    }

    showInvalidCells() {
        this.jExcelValidationErrors.map((error: any) => {
            error.columns.map((column: any) => {
                this.invalidateCell(column.id, error.row)
            })
        })

        const hasRequiredColumn = this.jExcelValidationErrors.find((error: any) => error.type == 'required');
        const hasUniqueColumn = this.jExcelValidationErrors.find((error: any) => error.type == 'unique');

        if (hasRequiredColumn)
            this.$message({
                message: "Bitte geben Sie die fehlenden Pflichtfelder ein.",
                type: "error"
            })

        if (hasUniqueColumn)
            this.$message({
                message: "Die " + hasUniqueColumn.columns[0].title + " muss eindeutig sein.",
                type: "error"
            })
    }

    validateCell(columnIndex: number, value: any) {
        let isRequired = this.jExcelColumns.find((column: any) => column.id && column.id == columnIndex)?.required
        return (!!isRequired && !!value) || !isRequired
    }

    searchJExcel(search: string) {
        this.jExcelObj.search(search)
    }

    updateSearch(search: any, searchRegEx: any) {
        this.search = search
        this.searchRegEx = searchRegEx
        this.searchJExcel(search)
    }

    getAndShowValidationErrorsOnJExcelTable() {
        let dataTable = this.jExcelObj.getJson()
        const errors = getValidationErrorsFromDataTable(dataTable, this.jExcelColumns)
        errors.map((error: any) => {
            error.columns.map((column: any) => {
                this.invalidateCell(column.id, error.row)
            })
        })
        return errors
    }

    saveAndRefreshJExcel(
        upsertMutation: any,
        listObject: any,
        config?: { table: string, fields: string[], lists?: any },
        callbackFnSuccess?: any,
        callbackFnError?: any,
        language?: string
    ) {
        let dataTable = this.jExcelObj.getJson()
        const isQueryObject = !!listObject.result

        this.jExcelValidationErrors = getValidationErrorsFromDataTable(dataTable, this.jExcelColumns)
        if (!this.jExcelValidationErrors.length) {
            let data = {
                objects: formatDataTableBeforeSave(dataTable, this.jExcelColumns, config?.table, language),
                idsToDelete: getRemovedIdsFromDataTable(isQueryObject ? listObject.result?.list : listObject, dataTable)
            } as any

            const relations = getRelationsToSave(dataTable, this.jExcelColumns, config?.table, language)
            Object.keys(relations)?.forEach((relationIndex: any) => {
                data[relationIndex] = _.uniqWith(relations[relationIndex], _.isEqual) ?? []
            })

            this.lbMutate(upsertMutation, data).then(() => {
                Vue.hasUnsavedChanges = false

                if (isQueryObject) {
                    const oldList = listObject.result?.list
                    listObject.refetch()?.then(() => {
                        const newList = listObject.result?.list
                        if (!!config?.table) this.addAuditLogsForChanges(config, oldList, newList)
                    })
                }

                if (!!callbackFnSuccess) callbackFnSuccess()
            })
        } else {
            this.showInvalidCells()
            if (!!callbackFnError) callbackFnError()
        }
    }

    addAuditLogsForChanges(auditLogConfig: {
        table: string,
        fields: string[],
        lists?: any
    }, oldList: any, newList: any) {
        const oldItemsIds = oldList.map((oldItem: any) => {

            let keptItem = newList.find((newItem: any) => newItem.id == oldItem.id)
            if (keptItem) {
                const createdAt = dayjs().toString()

                auditLogService.saveAuditLogItem(
                    auditLogConfig.table,
                    AuditLogType.update,
                    oldItem.id,
                    _.pick(oldItem, auditLogConfig.fields),
                    _.pick(keptItem, auditLogConfig.fields),
                    undefined, undefined,
                    createdAt
                )

                if (!!auditLogConfig.lists) {
                    _.keys(auditLogConfig.lists).forEach((relationTable: string) => {
                        auditLogService.saveAuditLogListByKeys(
                            auditLogConfig.table,
                            oldItem.id,
                            oldItem[auditLogConfig.lists[relationTable]],
                            keptItem[auditLogConfig.lists[relationTable]],
                            relationTable,
                            undefined,
                            createdAt)
                    })
                }

            } else auditLogService.saveAuditLogItem(
                auditLogConfig.table,
                AuditLogType.delete,
                oldItem.id,
                oldItem,
                {}
            )

            return oldItem.id
        })

        newList.filter((newItem: any) => !oldItemsIds.includes(newItem.id)).forEach((newItem: any) => {
            auditLogService.saveAuditLogItem(
                auditLogConfig.table,
                AuditLogType.insert,
                newItem.id,
                {},
                _.pick(newItem, auditLogConfig.fields)
            )
        })
    }


    findLists() {
        let findListsGql = gql`
            query findLists{
                departments:department(where: {active: {_eq: true}}) {
                    id
                    email
                    name:translated_name
                }
                clients:client(where: {active: {_eq: true}}) {
                    id
                    email
                    name
                }
                users:lb_user(where: {active: {_eq: true}}) {
                    id
                    email
                    name
                }
                suppliers:supplier(where: {active: {_eq: true}}) {
                    id
                    email
                    name
                }
                product_groups:product_group(where: {active: {_eq: true}}) {
                    id
                    name:translated_name
                }
                cost_centers:cost_center(where: {active: {_eq: true}}) {
                    id
                    name:translated_name
                    number
                }
                permissions:permission(where: {active: {_eq: true}}) {
                    id
                    name
                }
                task_groups:lb_user(where: {active: {_eq: true}, is_group: {_eq: true}}) {
                    id
                    name:translated_name
                }
            }
        `
        return gqlQueryRaw(findListsGql).then((result: any) => {
            return result.data
        })
    }

    beforeWindowUnload(e: any) {
        if (Vue.hasUnsavedChanges && !window.confirm(this.txtBeforeRouteLeave)) {
            // Cancel the event
            e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
            // Chrome requires returnValue to be set
            e.returnValue = '';
        }
    }

    formatFeedbackMsg(data: any) {
        let msg = this.$t(`system_comment.${data.type}`)
        _.keys(data?.values)?.forEach((key: string) => {
            msg = msg.replace(`{${key}}`, data?.values[key])
        })
        return msg.replace(/\{[^}]+\}/g, '')
    }

    formatErrorMsgFromExport(feedback: any) {
        return this.$t('invoice.page.export_error') +
            (feedback.data?.type != 'on_ms365_export_error_generic' ?
                this.formatFeedbackMsg(feedback.data) : feedback.message)
    }

    /**
     * END: Functions for JExcel
     * */

    markFormChange() {
        Vue.hasUnsavedChanges = true
    }

    getIntValue(value: any) {
        const onlyNumbers = value?.toString().replace(/\D/g, '')
        return onlyNumbers != "" ? parseInt(onlyNumbers) : null
    }

    hasPunchoutConfigByProvider(provider: string) {
        let configs = [`punchout.${provider}.identity`, `punchout.${provider}.url`]
        let configIsMissing = false
        configs.forEach((configKey: string) => {
            let config = this.configurations?.find((item: any) => item.key == configKey)
            configIsMissing = configIsMissing || !config || !config?.value
        })

        return !configIsMissing;
    }

    hasAnyPunchOutConfig(providers: any) {
        return !!providers.find((provider: any) => this.hasPunchoutConfigByProvider(provider.provider))
    }


    /**
     * Audit-Logs
     */

    getAffectedProperties(auditLog: AuditLog) {
        return auditLog.type == AuditLogType.update ? auditLog.affected_properties :
            (auditLog.type == AuditLogType.insert ?
                    this.getKeysOfAffectedProperties(auditLog.data_after, auditLog.relation_table) :
                    this.getKeysOfAffectedProperties(auditLog.data_before, auditLog.relation_table)
            )
    }

    getKeysOfAffectedProperties(data: any, relationTable?: string) {
        if (this.$t(relationTable + '.label_log') != "") return []
        const fieldsToIgnore = ["error", "title", "__typename", 'active', 'id']
        return _.keys(data)?.filter((key: string) =>
            typeof data[key] != "object"
            && !fieldsToIgnore.includes(key)
            && (!relationTable || !key.endsWith('_id'))
        )
    }

    getTranslatedLabel(auditLog: AuditLog, property: string) {
        return (!!auditLog.relation_table ? this.$t(auditLog.relation_table + '.' + property) : this.$t(auditLog.table + '.' + property)) || this.$t(property) || property
    }

    getHtmlTitleForRelationTable(auditLog: AuditLog, relationTable?: string) {
        const name = this.getNameFromAuditLogObject(auditLog, relationTable)

        if (!!relationTable && auditLog.relation_table === relationTable && !!name) {
            const description = this.getDescriptionForRelationTable(relationTable)
            const icon = this.getIconForAuditLogByType(auditLog)
            return `<b>${description}:</b><br><div style="display: flex">${icon}&nbsp; ${name}</div><br>`
        }

        return ""
    }

    getDescriptionForRelationTable(relationTable: string) {
        return this.$t(relationTable + '.description')
    }

    getNameFromAuditLogObject(auditLog: AuditLog, relationTable?: string) {
        return this.getName(auditLog.data_before, relationTable) ?? this.getName(auditLog.data_after, relationTable)
    }

    getName(data: any, relationTable?: string) {
        if (!data) return null
        const template = omitWarning(() => {
            return this.$t(relationTable + '.label_log')
        })
        let templateFields = omitWarning(() => {
            return this.$t(relationTable + '.label_log_fields')
        })
        let table = omitWarning(() => {
            return this.$t(relationTable + '.table_log')
        })

        templateFields = templateFields != "" ? templateFields.split(',') : null
        const label = this.formatLabelFromTemplate(template, data, templateFields)
        table = table != "" ? table : relationTable
        return label || (!!table && !!data[table]?.name ? data[table].name : null)
    }

    formatLabelFromTemplate(template: string, data: any, templateFields?: any) {
        const label = template + ''
        if (template != "") {
            (templateFields ?? _.keys(data)).forEach((key: string) => {
                if (typeof data[key] != 'object' || data[key] == null)
                    template = template.replace(key, this.getTextAndFormatIfDate(data[key]))
            })
            return label != template ? template : null
        }
        return this.getTextAndFormatIfDate(data['name'])
    }

    getTextAndFormatIfDate(str: string) {
        return checkIfStringIsValidISODate(str) ? this.lbDate(str) :
            (this.isStringBoolean(str) ? this.getBooleanText(str) : (str ?? ''))
    }

    isStringBoolean(str: any) {
        return ['true', true, 'false', false].includes(str) || typeof str == 'boolean'
    }

    getBooleanText(str: any) {
        return str ? 'Ja' : 'Nein'
    }

    formatValueFromTemplate(str: string, property?: string, auditLog?: AuditLog) {
        const table = auditLog?.relation_table ?? auditLog?.table
        const type = omitWarning(() => {
            return this.$t(table + '.type_' + property)
        })
        if (type == 'float') str = parseFloat(str).toFixed(2).replace(".", ",")
        const template = omitWarning(() => {
            return this.$t(table + '.template_' + property)
        }).replace('$1', str)
        const text = template != "" ? template : str
        return this.getTextAndFormatIfDate(text)
    }

    getAuditLogForProperty(auditLog: AuditLog, property: string) {
        const isForeignKey = property.endsWith("_id")
        const isInsertType = auditLog.type == AuditLogType.insert
        const propertyName = isForeignKey ? property.replace("_id", "_name") : property
        const propertyTranslationName = property.replace("_id", "") + "_names"
        const hasTranslationBefore = !!auditLog.data_before && auditLog.data_before[propertyTranslationName]?.length > 0
        const hasTranslationAfter = !!auditLog.data_after && auditLog.data_after[propertyTranslationName]?.length > 0
        const hasDataBefore = !!auditLog.data_before && (hasTranslationBefore || this.propertyHasValue(auditLog.data_before[property]))
        const hasDataAfter = !!auditLog.data_after && (hasTranslationAfter || this.propertyHasValue(auditLog.data_after[property]))

        const dataBefore = hasDataBefore ?
            (hasTranslationBefore ?
                    this.getTranslation(auditLog.data_before, propertyTranslationName) :
                    this.formatValueFromTemplate(auditLog.data_before[propertyName] ?? auditLog.data_before[property], property, auditLog)
            ) :
            (isInsertType ? '' : this.$t('audit_log.none'))

        const dataAfter = hasDataAfter ?
            (hasTranslationAfter ?
                this.getTranslation(auditLog.data_after, propertyTranslationName) :
                this.formatValueFromTemplate(auditLog.data_after[propertyName] ?? auditLog.data_after[property], property, auditLog)) :
            this.$t('audit_log.none')

        const txtDataBefore = isInsertType ? '' : `<br><i>${dataBefore}</i> <b>&nbsp; →  &nbsp; </b>`
        let content = hasDataBefore || hasDataAfter ?
            `<b>${this.getTranslatedLabel(auditLog, property)}:</b>${txtDataBefore} <i>${dataAfter}</i>` : false
        if (!!content && auditLog.relation_table) content = `<div style="margin-left:20px">${content}</div>`

        return content
    }

    propertyHasValue(value: any) {
        return !!value || (value == false || value == 'false' || value == 0)
    }


    getAuditLogForCheckbox(auditLog: AuditLog) {
        const differences = _.xor(auditLog.data_before, auditLog.data_after)
        const defaultParams = {export_name: this.exportModuleName}
        return differences.map((item: any) => {
            return this.$t(`${auditLog.table}.${auditLog.relation_table}.${item}`, defaultParams)
                + ": <br><i>" +
                (auditLog.data_before.includes(item) ? this.$t('yes') : this.$t('no')) +
                "</i> <b>&nbsp; →  &nbsp; </b><i>" +
                (auditLog.data_after.includes(item) ? this.$t('yes') : this.$t('no')) + "</i>"
        }).join("<br><br>")
    }

    getAuditLogTypeLabel(auditLogs: AuditLog[]) {
        const type = auditLogs.length == 1 ? auditLogs[0].type : AuditLogType.update
        return this.$t('audit_log.type.' + type)
    }

    getFormattedHtmlTextFromAuditLog(auditLog: AuditLog, relationTable?: string, length?: number) {
        if (auditLog.type === AuditLogType.checkbox) return this.getAuditLogForCheckbox(auditLog)

        const htmlText: string = this.getHtmlTitleForRelationTable(auditLog, relationTable)

        if (this.shouldShowAffectedProperties(auditLog, relationTable)) {
            const affectedProperties = this.getAffectedProperties(auditLog)
            if (affectedProperties.length > 0) {
                const propsInFormSequence = this.getPropsInFormSequence(auditLog, relationTable)?.filter((prop: string) => affectedProperties.includes(prop))
                const props = propsInFormSequence?.length > 0 ? propsInFormSequence : affectedProperties
                return htmlText + props.map((property: string) => this.getAuditLogForProperty(auditLog, property))
                    ?.filter((it: any) => !!it)
                    .map((text: any) => length ? this.getFirstChars(text, length) : text)
                    .map((text: any) => `<div style="margin-bottom: 5px">${text}</div>`)
                    .join('')
            }
            return htmlText
        } else if (!relationTable) {
            if (auditLog.type === AuditLogType.delete) return this.$t('audit_log.msg.was_deleted')
            else if (auditLog.type === AuditLogType.insert) return this.$t('audit_log.msg.was_inserted')
        } else return htmlText
    }

    getPropsInFormSequence(auditLog: AuditLog, relationTable?: string) {
        const table = relationTable && relationTable == auditLog.relation_table ? relationTable : auditLog.table
        const props = this.$t(`${table}.form_sequence`).split(',')
        return props.map((prop: string) => prop.trim())
    }

    mustShowGroupedRelationTable(auditLogs: AuditLog[]) {
        return !!auditLogs[0].relation_table && !this.shouldShowAffectedProperties(auditLogs[0], auditLogs[0].relation_table) && !this.isAuditLogTypeCheckbox(auditLogs[0])
    }

    isAuditLogTypeCheckbox(auditLog: AuditLog) {
        return auditLog.type === AuditLogType.checkbox
    }

    getFormattedHtmlTextFromAuditLogsForRelationTable(auditLogs: AuditLog[]) {
        const htmlText: string = `<b>${this.getDescriptionForRelationTable(auditLogs[0].relation_table)}</b>`
        return htmlText + this.getAuditLogsSortedByType(auditLogs).map((auditLog: AuditLog) => {
            const icon = this.getIconForAuditLogByType(auditLog)
            return icon + this.getNameFromAuditLogObject(auditLog, auditLog.relation_table)
        }).map((text: any) => `<div style="display:flex;margin-bottom: 2px;align-items: center">${text}</div>`)
            .join('') + "<br>"
    }

    iconSquarePen = "<svg xmlns=\"http://www.w3.org/2000/svg\" height=\"1em\" viewBox=\"0 0 448 512\"><path fill='currentColor' d=\"M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM325.8 139.7l14.4 14.4c15.6 15.6 15.6 40.9 0 56.6l-21.4 21.4-71-71 21.4-21.4c15.6-15.6 40.9-15.6 56.6 0zM119.9 289L225.1 183.8l71 71L190.9 359.9c-4.1 4.1-9.2 7-14.9 8.4l-60.1 15c-5.5 1.4-11.2-.2-15.2-4.2s-5.6-9.7-4.2-15.2l15-60.1c1.4-5.6 4.3-10.8 8.4-14.9z\"/></svg>"
    iconSquarePlus = "<svg xmlns=\"http://www.w3.org/2000/svg\" height=\"1em\" viewBox=\"0 0 448 512\"><path fill='currentColor'  d=\"M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM200 344V280H136c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V168c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H248v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z\"/></svg>"
    iconSquareMinus = "<svg xmlns=\"http://www.w3.org/2000/svg\" height=\"1em\" viewBox=\"0 0 448 512\"><path fill='currentColor'  d=\"M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm88 200H296c13.3 0 24 10.7 24 24s-10.7 24-24 24H152c-13.3 0-24-10.7-24-24s10.7-24 24-24z\"/></svg>"

    getIconForAuditLogByType(auditLog: AuditLog) {
        switch (auditLog.type) {
            case AuditLogType.insert:
                return `<div class="icon-audit-log-type insert">${this.iconSquarePlus}</div>`
            case AuditLogType.update:
                return `<div class="icon-audit-log-type update">${this.iconSquarePen}</div>`
            case AuditLogType.delete:
                return `<div class="icon-audit-log-type delete">${this.iconSquareMinus}</div>`
        }
    }


    getAuditLogsSortedByType(auditLogs: AuditLog[]) {
        return _.sortBy(auditLogs, "type")
    }

    getAuditLogsGroupedByRelationTable(auditLogs: AuditLog[]) {
        const groups = _.groupBy(auditLogs, "relation_table")
        return sortDictionaryOnKeys(groups)
    }


    shouldShowAffectedProperties(auditLog: AuditLog, relationTable?: string) {
        const hasMoreFkeys = _.keys(auditLog.data_after || auditLog.data_before)?.filter((key: string) => key.endsWith('_id'))?.length > 1
        return auditLog.type === AuditLogType.update ||
            (!!relationTable && auditLog.type === AuditLogType.insert && !!auditLog.data_after[auditLog.table + '_id'] && !hasMoreFkeys)
    }

    //PUNCH-IN
    sendPunchInStatusUpdate(code: string, documentId: number) {
        this.lbFetch({
            url: `/api/punchin/status-update/${code}/document/${documentId}`,
            showError: false
        }).then(r => {
        })
    }


    isLoggedAsOtherUser() {
        return !!localStorage.getItem(LSK_ACCESS_JWT_OTHER) || !!localStorage.getItem(LSK_REFRESH_JWT_OTHER)
    }

    logoutAsOtherUser() {
        localStorage.removeItem(LSK_ACCESS_JWT_OTHER)
        localStorage.removeItem(LSK_REFRESH_JWT_OTHER)
        this.emitter.emit("reload-navbar")
        this.$router.push({name: 'list-user'})
    }

    areAllModulesActive() {
        return this.isInvoiceModuleActive() && this.isProcurementModuleActive()
    }

    isOnlyInvoiceModuleActive() {
        return this.isInvoiceModuleActive() && !this.isProcurementModuleActive()
    }

    isOnlyProcurementModuleActive() {
        return this.isProcurementModuleActive() && !this.isInvoiceModuleActive()
    }

    isInvoiceModuleActive() {
        const config = this.configurations?.find((item: any) => item.key == 'system.modules.invoice')
        return !config  //new tenants have no config saved yet and the default is true
            || config.value == 'true'
    }

    isProcurementModuleActive() {
        const config = this.configurations?.find((item: any) => item.key == 'system.modules.procurement')
        return !config  //new tenants have no config saved yet and the default is true
            || config.value == 'true'
    }

    isPermissionsModuleActive() {
        const config = this.configurations?.find((item: any) => item.key == 'system.modules.permissions')
        return config?.value == 'true'
    }

    isMultilingualismModuleActive() {
        const config = this.configurations?.find((item: any) => item.key == 'system.modules.multilingualism')
        return config?.value == 'true'
    }

    isDatevExportActive() {
        const config = this.configurations?.find((item: any) => item.key == 'system.modules.invoice.export')
        return !config || config?.value == 'datev'
    }

    isMS365ExportActive() {
        const config = this.configurations?.find((item: any) => item.key == 'system.modules.invoice.export')
        return config?.value == 'ms365'
    }

    shouldSaveLinesFromInvoiceRecognition() {
        const config = this.configurations?.find((item: any) => item.key == 'invoice.recognition.lines')
        return !config || config?.value == 'true'
    }

    isProcurementRequiredOnInvoiceForm() {
        const config = this.configurations?.find((item: any) => item.key == 'invoice.form.procurement-required')
        return config?.value == 'true'
    }

    getProcurementStatusLabelByBcase(bcase: any, currentEntry?: any) {
        const procurement = bcase.procurement ?? bcase
        currentEntry = currentEntry || bcase.timeline?.current_entry[0]
        return omitWarning(() => {
                return this.$t('procurement.statuses.' + procurement?.status)
            })
            || currentEntry?.name
            || omitWarning(() => {
                return this.$t('timeline.text.' + currentEntry?.type + '.name', currentEntry?.name_params)
            })
    }

    getProcurementStatusLabelByStatus(status: string) {
        return omitWarning(() => {
            return this.$t('procurement.statuses.' + status)
        })
    }

    findProcurementStatusByLabel(label: string) {
        const statuses = this.getAllProcurementStatuses()
        return _.keys(statuses).find(key => (statuses[key]).toLowerCase().indexOf(label.toLowerCase()) >= 0)
    }

    getAllProcurementStatuses() {
        return this.$i18n.messages[this.$i18n.getLocale()]?.procurement?.statuses
    }

    getAllTimelineEntryTypes() {
        const texts = this.$i18n.messages[this.$i18n.getLocale()]?.timeline?.text
        let types: any = {}
        _.keys(texts)?.filter(type => !!texts[type].is_searchable)?.forEach(type => {
            types[type] = texts[type].simple_name || texts[type].name
        })
        return types
    }

    findTimelineEntryTypeByLabel(label: string) {
        const types = this.getAllTimelineEntryTypes()
        return _.keys(types).find(key => (types[key]).toLowerCase().indexOf(label.toLowerCase()) >= 0)
    }


    getInvoiceStatusLabel(bcase: any, currentEntry?: any) {
        const invoice = bcase.invoice ?? bcase
        currentEntry = currentEntry || bcase.timeline?.current_entry[0]
        return omitWarning(() => {
                return this.$t('invoice.statuses.' + invoice?.status)
            })
            || currentEntry?.name
            || omitWarning(() => {
                return this.$t('timeline.text.' + currentEntry?.type + '.name', currentEntry?.name_params)
            })

    }

    getInvoiceStatusLabelByStatus(status: string) {
        return omitWarning(() => {
            return this.$t('invoice.statuses.' + status)
        })
    }


    findInvoiceStatusByLabel(label: string) {
        const statuses = this.getAllInvoiceStatuses()
        return _.keys(statuses).find(key => (statuses[key]).toLowerCase().indexOf(label.toLowerCase()) >= 0)
    }

    getAllInvoiceStatuses() {
        return this.$i18n.messages[this.$i18n.getLocale()]?.invoice?.statuses
    }


    isEmptyValue(value: any) {
        return ['', null, undefined, []].includes(value) || (isObject(value) && isEmpty(value))
    }

    roundNumber(number: number, precision: number = 2) {
        return Math.round(number * Math.pow(10, precision)) / Math.pow(10, precision)
    }

    confirmBeforeContinue(msg: string, title: string = '', confirmBtn: string = this.$t('procurement.page.default_confirm_btn'), positiveConfirmation: boolean = true) {
        return this.$confirm(msg, {
            confirmButtonText: confirmBtn,
            confirmButtonClass: positiveConfirmation ? 'el-button--primary solid' : 'el-button--danger solid',
            cancelButtonText: this.$t('cancel'),
            dangerouslyUseHTMLString: true,
            title: title,
            center: true,
        }).then(() => {
            return new Promise((resolve: any) => resolve(true));
        }).catch(() => {
            return new Promise((resolve: any) => resolve(false));
        })
    }

    get procurementLabels() {
        return {
            order_number_formatted: this.$t('overview_page.export.labels.procurement.order_number_formatted'),
            name: this.$t('overview_page.export.labels.procurement.name'),
            creator_name: this.$t('overview_page.export.labels.procurement.creator_name'),
            total_price_brutto: this.$t('overview_page.export.labels.procurement.total_price_brutto'),
            total_price_netto: this.$t('overview_page.export.labels.procurement.total_price_netto'),
            created_at: this.$t('overview_page.export.labels.procurement.created_at'),
            client_number: this.$t('overview_page.export.labels.procurement.client_number'),
            client_name: this.$t('overview_page.export.labels.procurement.client_name'),
            department_name: this.$t('overview_page.export.labels.procurement.department_name'),
            cost_center_name: this.$t('overview_page.export.labels.procurement.cost_center_name'),
            cost_center_number: this.$t('overview_page.export.labels.procurement.cost_center_number'),
            consignee_name: this.$t('overview_page.export.labels.procurement.consignee_name'),
            shipping_date: this.$t('overview_page.export.labels.procurement.shipping_date'),
            shipping_name: this.$t('overview_page.export.labels.procurement.shipping_name'),
            shipping_consignee: this.$t('overview_page.export.labels.procurement.shipping_consignee'),
            shipping_dropoff_location: this.$t('overview_page.export.labels.procurement.shipping_dropoff_location'),
            shipping_address: this.$t('overview_page.export.labels.procurement.shipping_address'),
            shipping_complement: this.$t('overview_page.export.labels.procurement.shipping_complement'),
            shipping_country: this.$t('overview_page.export.labels.procurement.shipping_country'),
            shipping_zipcode: this.$t('overview_page.export.labels.procurement.shipping_zipcode'),
            shipping_city: this.$t('overview_page.export.labels.procurement.shipping_city'),
            shipping_phone_country_code: this.$t('overview_page.export.labels.procurement.shipping_phone_country_code'),
            shipping_phone_number: this.$t('overview_page.export.labels.procurement.shipping_phone_number'),
            billing_name: this.$t('overview_page.export.labels.procurement.billing_name'),
            billing_consignee: this.$t('overview_page.export.labels.procurement.billing_consignee'),
            billing_dropoff_location: this.$t('overview_page.export.labels.procurement.billing_dropoff_location'),
            billing_address: this.$t('overview_page.export.labels.procurement.billing_address'),
            billing_complement: this.$t('overview_page.export.labels.procurement.billing_complement'),
            billing_country: this.$t('overview_page.export.labels.procurement.billing_country'),
            billing_zipcode: this.$t('overview_page.export.labels.procurement.billing_zipcode'),
            billing_city: this.$t('overview_page.export.labels.procurement.billing_city'),
            billing_phone_country_code: this.$t('overview_page.export.labels.procurement.billing_phone_country_code'),
            billing_phone_number: this.$t('overview_page.export.labels.procurement.billing_phone_number'),
            status: this.$t('overview_page.export.labels.procurement.status'),
        } as any
    }

    get invoiceLabels() {
        return {
            invoice_number_formatted: this.$t('overview_page.export.labels.invoice.invoice_number_formatted'),
            number: this.$t('overview_page.export.labels.invoice.number'),
            creator_name: this.$t('overview_page.export.labels.invoice.creator_name'),
            procurement_name: this.$t('overview_page.export.labels.invoice.procurement_name'),
            procurement_number: this.$t('overview_page.export.labels.invoice.procurement_number'),
            cost_center_name: this.$t('overview_page.export.labels.invoice.cost_center_name'),
            cost_center_number: this.$t('overview_page.export.labels.invoice.cost_center_number'),
            total_price_netto: this.$t('overview_page.export.labels.invoice.total_price_netto'),
            total_price_brutto: this.$t('overview_page.export.labels.invoice.total_price_brutto'),
            created_at: this.$t('overview_page.export.labels.invoice.created_at'),
            client_name: this.$t('overview_page.export.labels.invoice.client_name'),
            client_number: this.$t('overview_page.export.labels.invoice.client_number'),
            invoice_date: this.$t('overview_page.export.labels.invoice.invoice_date'),
            delivery_date: this.$t('overview_page.export.labels.invoice.delivery_date'),
            due_date: this.$t('overview_page.export.labels.invoice.due_date'),
            currency: this.$t('overview_page.export.labels.invoice.currency'),
            ledger_account_name: this.$t('overview_page.export.labels.invoice.ledger_account_name'),
            ledger_account_number: this.$t('overview_page.export.labels.invoice.ledger_account_number'),
            posting_key_number: this.$t('overview_page.export.labels.invoice.posting_key_number'),
            posting_key_tax: this.$t('overview_page.export.labels.invoice.posting_key_tax'),
            supplier_name: this.$t('overview_page.export.labels.invoice.supplier_name'),
            status: this.$t('overview_page.export.labels.invoice.status'),
        } as any
    }


    getBudgetLabel(budget: any, showTotal: boolean = false) {
        let label = this.getBudgetName(budget, showTotal)
        if (budget.end) return label + ' - ' + this.lbDate(budget.start) + ' - ' + this.lbDate(budget.end)
        else return label + ' - ' + this.$t('dashboard_page.budgets.from') + ' ' + this.lbDate(budget.start)
    }

    getBudgetName(budget: any, showTotal: boolean = false) {
        const hasName = !!budget.name || !!budget?.ledger_account?.name
        let name = budget.name || budget?.ledger_account?.name
        if (hasName)
            if (showTotal) return `${name} | ${this.getCurrencyFormat(budget.value, undefined, true)}`
            else return name
        return this.getCurrencyFormat(budget.value, undefined, true)
    }
}

export class GqlMutationWithData {
    constructor(
        public gql: any,
        public data: any
    ) {
    }
}

